import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import People from '../../components/people';
import CTA from '../../components/CTA';

const Index = ({ data }) => {
  const mentors = data.allPeopleJson.edges.map(item => item.node);

  return (
    <Layout bodyClass="page-mentors">
      <SEO title="Team" />
      <div className="container">
        <div className="intro">
          <div className="row">
            <div className="col-12">
              <h1>Mentors</h1>
              <p>
                BAASIS LABS partnered with the best entrepreneurs from different companies to provide our startups with the best possible mentoring across all areas needed to build a successful company.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <People people={ mentors } />
        </div>

        <div className="col-12 text-center">
          <CTA props={{ link: '/application/mentors', title: 'Become a Mentor' }} />
        </div>

      </div>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query {
    allPeopleJson(filter: {teams: {in: ["mentors"]}, active: {eq: true}}) {
      edges {
        node {
          id
          name
          image
          jobtitle
          email
          company {
            name
            url
          }
          description
          linkedinurl
        }
      }
    }
  }
`;

export default Index;
