import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';

const CTA = ({ props: { link, title, background } }) => (
  <Link
    className="button button-primary mb-5"
    to={link}
    style={{ background }}
  >
    {title}
  </Link>
);

CTA.propTypes = {
  props: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default CTA;
