import React from 'react';
import { withPrefix } from 'gatsby';
import { Link } from 'rebass';
import PropTypes from 'prop-types';
import SocialLink from './SocialLink';

const People = ({ people }) => (
  <div className="container pb-6 people">
    <div className="row">
      <div className="card-deck d-block d-sm-flex align-items-stretch col-12">
      {people.map(person => (
        <div key={person.id} className="col-12 col-md-6 col-lg-6 mb-2">
          <div className="team card d-flex flex-column h-100">
            <div className="card-header d-flex w-100">
              <div className="card-header-left">
                {person.image && (
                  <div className="card-image">
                    <img
                      alt={person.name}
                      src={withPrefix(person.image)}
                    />
                  </div>
                )}
              </div>
              <div className="card-right flex-grow-1">
                <h2 className="card-title mb-1">{person.name}</h2>
                <ul className="card-meta">
                  <li className="mb-1">
                    <Link color="false" href={person.company.url} target="_blank">
                      {person.company.name}
                    </Link>
                  </li>
                  <li>
                    <strong>{person.jobtitle}</strong>
                  </li>
                  <li>
                    {person.linkedinurl && (
                      <SocialLink
                        icon="linkedin"
                        url={person.linkedinurl}
                      />
                    )}
                    &nbsp;
                    {person.email && (
                      <SocialLink
                        icon="envelope"
                        url={`mailto:${person.email}`}
                      />
                    )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="team-content">
              {person.description}
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  </div>
);

People.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default People;
